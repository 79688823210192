<template>
  <div>
    <div id="s-html5-qrcode-scanner"></div>
  </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode'

export default {
  props: {
    qrbox: {
      type: Object,
      default: () => ({ width: 250, height: 250 })
    },
    fps: {
      type: Number,
      default: 120
    },
  },
  data: () => ({
    html5QrCode: null,
    errorCount:0,
  }),
  mounted() {
    console.log("QRCodeScanner.vue mounted");
    this.initializeScanner()
  },
  beforeUnmount() {
    console.log("QRCodeScanner.vue beforeUnmount");
    this.stopScanner()
  },
  methods: {
    initializeScanner() {
      this.html5QrCode = new Html5Qrcode("s-html5-qrcode-scanner");

      const config = {
        fps: this.fps,
        qrbox: this.qrbox,
        aspectRatio: 1.0,
        disableFlip: false, // 允許鏡像翻轉，可能有助於某些設備
        experimentalFeatures: {
          useBarCodeDetectorIfSupported: true // 使用瀏覽器的原生條碼檢測器（如果支持）
        },
        focusMode: 'continuous'
      };

      this.html5QrCode.start(
        {
          facingMode: "environment"
        },
        config,
        this.onScanSuccess,
        this.onScanFailure
      ).catch(err => {
        console.error("啟動掃描器失敗:", err);
      });
    },
    onScanSuccess(decodedText, decodedResult) {
      console.log(`掃描結果: ${decodedText}`, decodedResult);
      this.$emit('onScan', decodedText);
      this.stopScanner();
    },
    onScanFailure(error) {
      if (this.errorCount < 10) {
        console.warn(`二維碼掃描失敗 (${this.errorCount + 1}/10): ${error}`);
        this.errorCount++;
      } else if (this.errorCount === 10) {
        console.warn("二維碼掃描持續失敗，停止日誌輸出以避免刷屏");
        this.errorCount++;
      }
    },
    stopScanner() {
      if (this.html5QrCode && this.html5QrCode.isScanning) {
        this.html5QrCode.stop().catch(err => {
          console.error("停止掃描器失敗:", err);
        });
      }
    }
  }
}
</script>

<style scoped>
#s-html5-qrcode-scanner {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
</style>
