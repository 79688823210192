import https from "../https"

const games = {
  async checkMemberByPhone(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/check-member`, { params });
  },
  async sendCoupon(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/send-coupon`, params);
  },
  async getCouponList(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/coupon-list`, { params });
  },
  async registerCustomer(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/register`, { params });
  },
  async checkIsBindStore(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/check-store`, { params });
  },
  async bindStore(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/bind-store`, { params });
  }
}

export default games
